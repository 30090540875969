.purple-btn{
    background-color: #A80048;
    border-color: #A80048;
    border-radius: 6px;
    padding: 8px 12px;
    color:#FFF;
}
a.purple-btn:hover,
a.purple-btn:hover,
a.purple-btn:focus,
a.purple-btn:active,
a.purple-btn:disabled,
.purple-btn:hover,
.purple-btn:focus,
.purple-btn:active,
.purple-btn:disabled
{
    background-color: #820138;
    border-color: #820138;
    color:#FFF;
    text-decoration: none;
}

.purple-btn.btn-sm{
    padding: 0.25rem 0.5rem;
}
.purple-btn-outline{
    & {
        background-color: #fff;
        border-color: #A80048;
        border-radius: 6px;
        padding: 8px 12px;
        color: #A80048;
    }
    &:hover{
        background-color: #A80048;
        color: #fff;
        border-color: #A80048;
    }
}

.purple-btn:hover,
.purple-btn:focus,
.purple-btn:active,
.purple-btn:disabled
{
    background-color: #fff;
    border-color: #820138;
    color:#820138;
}

.purple-btn.btn-sm{
    padding: 0.25rem 0.5rem;
}

/** white **/
.white-btn{
    background-color: #ecf0f1;
    border-color: #bdc3c7;
    border-radius: 6px;
    padding: 8px 12px;
    color:#000;
}
a.white-btn:hover,
a.white-btn:hover,
a.white-btn:focus,
a.white-btn:active,
a.white-btn:disabled,
.white-btn:hover,
.white-btn:focus,
.white-btn:active,
.white-btn:disabled
{
    background-color: #95a5a6;
    border-color: #7f8c8d;
    color:#000;
    text-decoration: none;
}

.white-btn.btn-sm{
    padding: 0.25rem 0.5rem;
}
.white-btn-outline{
    & {
        background-color: #95a5a6;
        border-color: #7f8c8d;
        color:#000;
        border-radius: 6px;
        padding: 8px 12px;
    }
    &:hover{
        background-color: #95a5a6;
        border-color: #7f8c8d;
        color:#000;
    }
}

.white-btn:hover,
.white-btn:focus,
.white-btn:active,
.white-btn:disabled
{
    background-color: #bdc3c7;
    border-color: #7f8c8d;
    color:#000;
}

.white-btn.btn-sm{
    padding: 0.25rem 0.5rem;
}
